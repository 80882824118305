<template>
  <div id="app">
    <Loading v-show="LOADING"></Loading>
    <!-- 缓存的页面，缓存$route.meta.keepAlive为true的组件 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 不缓存的页面，不缓存$route.meta.keepAlive为false的组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loading from "./components/loading.vue";
export default {
  computed: {
    ...mapState(["LOADING"]),
  },
  name: "App",
  components: { Loading },
};
</script>

<style>
#app {
  width: 100%;
  min-height: 100vh;
}
a {
  display: none;
}
</style>
